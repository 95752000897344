@import "_variables.scss";

@import "~bootstrap/scss/bootstrap";

@import "_bootswatch.scss";

body {
  height: 100vh;
}

a {
  color: #343a40;
  font-weight: bold;
  text-decoration: none;
}



