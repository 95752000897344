// Minty 5.1.3
// Bootswatch


// Variables

$web-font-path: "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap" !default;
@if $web-font-path {
  @import url($web-font-path);
}

// Navbar

.navbar {
  font-family: $headings-font-family;
}

.bg-dark {
  background-color: $secondary !important;
}

.border-dark {
  border-color: $secondary !important;
}

// Buttons

.btn {
  font-family: $headings-font-family;

  &,
  &:hover {
    color: $white;
  }

  &-light,
  &-light:hover {
    color: $gray-700;
  }

  &-link,
  &-link:hover {
    color: $primary;
  }

  &-link.disabled:hover {
    color: $gray-600;
  }

  &-outline-primary {
    color: $primary;
  }

  &-outline-secondary {
    color: $secondary;
  }

  &-outline-success {
    color: $success;
  }

  &-outline-info {
    color: $info;
  }

  &-outline-warning {
    color: $warning;
  }

  &-outline-danger {
    color: $danger;
  }

  &-outline-dark {
    color: $dark;
  }

  &-outline-light {
    color: $light;
  }
}

// Forms

legend {
  font-family: $headings-font-family;
}

// Navs

.dropdown-menu {
  font-family: $font-family-sans-serif;
}

.breadcrumb {
  a {
    color: $navbar-dark-color;
  }

  a:hover {
    color: $white;
    text-decoration: none;
  }
}

// Indicators

.alert {
  a,
  .alert-link {
    color: $white;
  }

  &-light {

    &,
    a:not(.btn),
    .alert-link {
      color: $body-color;
    }
  }
}

.badge {
  color: $white;

  &.bg-light {
    color: $gray-700;
  }
}

// Containers

.card,
.list-group-item {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }
}
